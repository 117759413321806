import React from "react"
import UnAuthContent from "../gatsby-theme-wild-child/components/auth/UnAuthContent"
import Disclaimer from "src/gatsby-theme-wild-child/components/Disclaimer"

function DisclaimerPage() {
  return (
    <UnAuthContent>
      <Disclaimer />
    </UnAuthContent>
  )
}

export default DisclaimerPage

export const Head = () => (
  <>
    <title>
      TaxGPT by Virtuzone | Disclaimer
    </title>
    <meta
      name="facebook-domain-verification"
      content="0qis6k25boblo98elnosvjz5ws7gtm"
    />
    <meta name="google-site-verification" content="fdkrhMVPDQOPHf3A4czsCkEuBbJ_lkvh8yj01E6fn4Q" />
  </>
)
